// import React, { useState } from "react";

// import { useForm } from "react-hook-form";
// import { useSummaryTool } from "hooks/Forms/useSummaryTool";
// import { useLocation } from "@reach/router";
// import Loader from "components/loader";
// import formCommonPostData from "utils/form-common-post-data";
// import { Link } from "gatsby";
// import Toogle_icon from "assets/toogle_icon.svg";
// import ToolsBreadcrumb from "components/ToolsBredcrumb";

// function FirstSummaryTool() {
//   const { status, mutateAsync } = useSummaryTool();
//   const location = useLocation();
//   const [toggleTool, setToggleTool] = useState(false);
//   const [enteredText, setEnteredText] = useState("");
//   const [summarizedText, setSummarizedText] = useState("");
//   const [isEnterText, setIsEnterText] = useState(true);
//   const [wordCount, setWordCount] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [messageText, setMessageText] = useState("");
//   const [urlText, setUrlText] = useState("");

//   // localStorage.setItem("enteredText","")

//   const {
//     register,
//     handleSubmit,
//     reset,
//     formState: { errors },
//   } = useForm();

//   const onSubmit = (data) => {
//     console.log(data,"data")
//     if (data["url"] == "" && data["message"] == "") {
//       alert("Please enter page url or text in box");
//       return false;
//     }

//     const postData1 = {
//       url: data.url,
//       text: data.message,
//       // pagelink: location.href,
//     };
    
//     const postData = { ...postData1, ...formCommonPostData() };
//     mutateAsync(postData).then((response) => {
    
    
//       setEnteredText((_) => {
//         if (postData.url) {
//           return postData.url;
//         } else {
//           return postData.text;
//         }
//       });
//       setEnteredText(postData.text);
//       if (response.success === 1) {
//         setEnteredText(response.data.original);
//         setSummarizedText(response.data.result);
//         setToggleTool(true);
//         reset();
//         window.scrollTo(0, 0);
//       } else {
//         alert(response.message);
//       }
//       console.log(summarizedText,"summarisedtext")
//       // if (response.success === 1) {
//       //   // setForStatus(true);
//       //   // return navigate("/");
//       // }
//       // if (response.message === "Invalid Token") {
//       //   // reset();
//       //   setErrorToken(true);
//       //   // return navigate("/");
//       // }
//       //  else {
//       //   setErrorStatus(true);
//       // }
//     });
//   };
//   // console.log(enteredText);
//   const handleTextChange = (e) => {
//     const text = e.target.value.trim();
//     setMessageText(text);
//     const words = text.split(/\s+/).filter((word) => word.length > 0);
//     setWordCount(words.length);
//   };
  
//   const clearText = () => {
//     setWordCount(0);
//     setMessageText("");
//     reset({ message: "" });
//   };
//   const handleLinkChange = (e) => {
//     const text = e.target.value;
//     setUrlText(text);
//   };
//   const clearLink = () => {
//     setUrlText("");
//     reset({ url: "" });
//   };
//     const toggleInput = (isTextInput) => {
//     setIsEnterText(isTextInput);
//   };

//   return (
//     <div className="bg-[#F5F5F5] ">
//       <div className="w-11/12 max-w-7xl mx-auto flex flex-col">
//         <div className="hidden lg:block w-full px-4 2xl:px-0 py-7 ">
//           <ToolsBreadcrumb />
//         </div>
//       </div>
//       <div className="  pb-[120px]">
//         <div className="max-w-7xl mx-auto  px-4 sm:px-6 mt-16">
//           <h1 className="text-[#5B93FF] font-semibold text-base font-primary text-center ">
//             SUMMARY GENERATOR
//           </h1>
//           <h2 className="mt-3 text-[#0A2641] font-primary text-[32px]  lg:text-[64px] font-bold leading-10 lg:leading-[77px] text-center ">
//             Summarize your long text for free
//           </h2>
//           <p className="mt-6 font-opensans max-w-3xl mx-auto text-[#424242] text-lg font-normal text-center">
//             Our text summarizer helps you quickly generate concise summaries
//             from articles, PDFs, and websites. Just paste your content or a URL,
//             and get a summary instantly.
//           </p>
//           <div className="grid lg:grid-cols-2 gap-3 mt-16">
//             <div className="bg-[#ffffff] rounded-lg shadow-lg p-5">
//               <div className=" flex gap-1">
//                 <button
//                   onClick={() => toggleInput(true)}
//                   className={`py-1 px-3 rounded-md font-semibold ${
//                     isEnterText
//                       ? "text-white bg-[#7AA7FF]"
//                       : "text-[#7AA7FF] bg-[#F0F5FF]"
//                   }`}
//                 >
//                   Enter Text
//                 </button>
//                 <button
//                   onClick={() => toggleInput(false)}
//                   className={`py-1 px-3 rounded-md font-semibold ${
//                     isEnterText
//                       ? "text-[#7AA7FF] bg-[#F0F5FF]"
//                       : "text-white bg-[#7AA7FF]"
//                   }`}
//                 >
//                   Enter URL
//                 </button>
//               </div>
//               <div className="mt-4">
//                 {isEnterText ? (
//                   <div>
//                     <textarea
//                       {...register("message")}
//                       value={messageText}
//                       className="min-h-[280px] font-opensans  placeholder-[#858585] text-black leading-[26px]  py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full"
//                       placeholder="Type or paste your text here"
//                       onChange={handleTextChange}
//                     />
//                     <div className="flex  justify-between items-center p-4 mt-8">
//                       <p className="text-right mt-2 text-sm text-[#9C9C9C]">
//                         {wordCount} words
//                       </p>
//                       <div className="flex gap-2">
//                         <button
//                           type="submit"
//                           className={` ${
//                             wordCount > 0
//                               ? "text-[#0A2641] border border-[#0A2641] cursor-pointer "
//                               : "border border-[#B0B0B0] text-[#B0B0B0]  "
//                           } flex gap-2 bg-white px-4 py-2 rounded-md`}
//                           onClick={clearText}
//                           disabled={wordCount === 0}
//                         >
//                           {wordCount > 0 ? (
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               width="22"
//                               height="22"
//                               viewBox="0 0 22 22"
//                               fill="none"
//                             >
//                               <path
//                                 d="M17.1492 12.413L9.97513 5.23889M20.2238 20.6119H6.90053M9.91083 19.6513L18.795 10.7671C20.0126 9.54954 20.6214 8.94075 20.8495 8.23873C21.0502 7.62121 21.0502 6.95603 20.8495 6.33852C20.6214 5.6365 20.0126 5.0277 18.795 3.81011L18.578 3.59307C17.3604 2.37547 16.7516 1.76667 16.0496 1.53857C15.4321 1.33793 14.7669 1.33793 14.1494 1.53857C13.4473 1.76667 12.8385 2.37547 11.621 3.59307L3.20497 12.009C1.98738 13.2266 1.37858 13.8354 1.15048 14.5375C0.949839 15.155 0.949839 15.8202 1.15048 16.4377C1.37858 17.1397 1.98738 17.7485 3.20497 18.9661L3.89023 19.6513C4.24473 20.0058 4.42199 20.1831 4.62884 20.3098C4.81223 20.4222 5.01217 20.5051 5.22132 20.5553C5.45722 20.6119 5.70789 20.6119 6.20924 20.6119H7.59182C8.09317 20.6119 8.34384 20.6119 8.57974 20.5553C8.78889 20.5051 8.98883 20.4222 9.17222 20.3098C9.37907 20.1831 9.55633 20.0058 9.91083 19.6513Z"
//                                 stroke="#0A2641"
//                                 stroke-width="2"
//                                 stroke-linecap="round"
//                                 stroke-linejoin="round"
//                               />
//                             </svg>
//                           ) : (
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               width="22"
//                               height="22"
//                               viewBox="0 0 22 22"
//                               fill="none"
//                             >
//                               <path
//                                 d="M17.1492 12.4131L9.97513 5.23898M20.2238 20.612H6.90053M9.91083 19.6514L18.795 10.7672C20.0126 9.54963 20.6214 8.94084 20.8495 8.23882C21.0502 7.6213 21.0502 6.95612 20.8495 6.33861C20.6214 5.63659 20.0126 5.02779 18.795 3.8102L18.578 3.59316C17.3604 2.37556 16.7516 1.76677 16.0496 1.53867C15.4321 1.33802 14.7669 1.33802 14.1494 1.53867C13.4473 1.76677 12.8385 2.37556 11.621 3.59316L3.20497 12.0091C1.98738 13.2267 1.37858 13.8355 1.15048 14.5375C0.949839 15.1551 0.949839 15.8202 1.15048 16.4378C1.37858 17.1398 1.98738 17.7486 3.20497 18.9662L3.89023 19.6514C4.24473 20.0059 4.42199 20.1832 4.62884 20.3099C4.81223 20.4223 5.01217 20.5051 5.22132 20.5554C5.45722 20.612 5.70789 20.612 6.20924 20.612H7.59182C8.09317 20.612 8.34384 20.612 8.57974 20.5554C8.78889 20.5051 8.98883 20.4223 9.17222 20.3099C9.37907 20.1832 9.55633 20.0059 9.91083 19.6514Z"
//                                 stroke="#B0B0B0"
//                                 stroke-width="2"
//                                 stroke-linecap="round"
//                                 stroke-linejoin="round"
//                               />
//                             </svg>
//                           )}
//                           Clear text
//                         </button>

//                         <button
//                           type="submit"
//                           className="bg-[#0A2641] text-white px-4 py-2 rounded-md"
//                         >
//                           {loading ? <Loader /> : "Summarize text"}
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 ) : (
//                   <div>
//                     <textarea
//                       {...register("url")}
//                       value={urlText}
//                       placeholder="Paste your link here"
//                       className="min-h-[220px] font-opensans  placeholder-[#858585] text-[#1463FF] leading-[26px]  py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full"
//                       onChange={handleLinkChange}
//                     />
//                     <div className="flex  justify-between items-center p-4 mt-8">
//                       <span></span>
//                       <div className="flex gap-2">
//                         <button
//                           type="submit"
//                           className={` ${
//                             urlText
//                               ? "text-[#0A2641] border border-[#0A2641] cursor-pointer "
//                               : "border border-[#B0B0B0] text-[#B0B0B0]  "
//                           } flex gap-2 bg-white px-4 py-2 rounded-md`}
//                           onClick={clearLink}
//                           disabled={urlText === 0}
//                         >
//                           {urlText ? (
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               width="22"
//                               height="22"
//                               viewBox="0 0 22 22"
//                               fill="none"
//                             >
//                               <path
//                                 d="M17.1492 12.413L9.97513 5.23889M20.2238 20.6119H6.90053M9.91083 19.6513L18.795 10.7671C20.0126 9.54954 20.6214 8.94075 20.8495 8.23873C21.0502 7.62121 21.0502 6.95603 20.8495 6.33852C20.6214 5.6365 20.0126 5.0277 18.795 3.81011L18.578 3.59307C17.3604 2.37547 16.7516 1.76667 16.0496 1.53857C15.4321 1.33793 14.7669 1.33793 14.1494 1.53857C13.4473 1.76667 12.8385 2.37547 11.621 3.59307L3.20497 12.009C1.98738 13.2266 1.37858 13.8354 1.15048 14.5375C0.949839 15.155 0.949839 15.8202 1.15048 16.4377C1.37858 17.1397 1.98738 17.7485 3.20497 18.9661L3.89023 19.6513C4.24473 20.0058 4.42199 20.1831 4.62884 20.3098C4.81223 20.4222 5.01217 20.5051 5.22132 20.5553C5.45722 20.6119 5.70789 20.6119 6.20924 20.6119H7.59182C8.09317 20.6119 8.34384 20.6119 8.57974 20.5553C8.78889 20.5051 8.98883 20.4222 9.17222 20.3098C9.37907 20.1831 9.55633 20.0058 9.91083 19.6513Z"
//                                 stroke="#0A2641"
//                                 stroke-width="2"
//                                 stroke-linecap="round"
//                                 stroke-linejoin="round"
//                               />
//                             </svg>
//                           ) : (
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               width="22"
//                               height="22"
//                               viewBox="0 0 22 22"
//                               fill="none"
//                             >
//                               <path
//                                 d="M17.1492 12.4131L9.97513 5.23898M20.2238 20.612H6.90053M9.91083 19.6514L18.795 10.7672C20.0126 9.54963 20.6214 8.94084 20.8495 8.23882C21.0502 7.6213 21.0502 6.95612 20.8495 6.33861C20.6214 5.63659 20.0126 5.02779 18.795 3.8102L18.578 3.59316C17.3604 2.37556 16.7516 1.76677 16.0496 1.53867C15.4321 1.33802 14.7669 1.33802 14.1494 1.53867C13.4473 1.76677 12.8385 2.37556 11.621 3.59316L3.20497 12.0091C1.98738 13.2267 1.37858 13.8355 1.15048 14.5375C0.949839 15.1551 0.949839 15.8202 1.15048 16.4378C1.37858 17.1398 1.98738 17.7486 3.20497 18.9662L3.89023 19.6514C4.24473 20.0059 4.42199 20.1832 4.62884 20.3099C4.81223 20.4223 5.01217 20.5051 5.22132 20.5554C5.45722 20.612 5.70789 20.612 6.20924 20.612H7.59182C8.09317 20.612 8.34384 20.612 8.57974 20.5554C8.78889 20.5051 8.98883 20.4223 9.17222 20.3099C9.37907 20.1832 9.55633 20.0059 9.91083 19.6514Z"
//                                 stroke="#B0B0B0"
//                                 stroke-width="2"
//                                 stroke-linecap="round"
//                                 stroke-linejoin="round"
//                               />
//                             </svg>
//                           )}
//                           Clear text
//                         </button>

//                         <button
//                           type="button"
//                           onClick={handleSubmit(onSubmit)}
//                           className="bg-[#0A2641] text-white px-4 py-2 rounded-md"
//                         >
//                           {loading ? <Loader /> : "Summarize link"}
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="bg-[#ffffff] opacity-50 rounded-lg shadow-lg p-5">
//               <div className=" flex justify-between gap-1">
//                 <p className="text-base text-[#B0B0B0] font-bold leading-6 font-opensans">
//                   Summarized text
//                 </p>
//                 <p className="text-base text-[#B0B0B0] font-bold leading-6 font-opensans flex gap-2 items-center">
//                   <img src={Toogle_icon} alt="/toogle_icon" />
//                   Show bullets
//                 </p>
//               </div>
//               <div className="mt-4">
//                 <textarea
//                   {...register("message")}
//                   className="min-h-[280px] font-opensans  placeholder-[#858585] text-black leading-[26px]  py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full"
//                 />
//               </div>
//               <p className="pt-6 px-2 mt-[40px] text-sm text-start text-[#9C9C9C]">
//                 -- words
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default FirstSummaryTool;




import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSummaryTool } from "hooks/Forms/useSummaryTool";
import { useLocation } from "@reach/router";
import Loader from "components/loader";
import formCommonPostData from "utils/form-common-post-data";
import { Link } from "gatsby";
import Toogle_icon from "assets/toogle_icon.svg";
import ToolsBreadcrumb from "components/ToolsBredcrumb";

function FirstSummaryTool() {
  const { status, mutateAsync } = useSummaryTool();
  const location = useLocation();
  const [toggleTool, setToggleTool] = useState(false);
  const [enteredText, setEnteredText] = useState("");
  const [summarizedText, setSummarizedText] = useState("");
  const [isEnterText, setIsEnterText] = useState(true);
  const [wordCount, setWordCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [urlText, setUrlText] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // const onSubmit = (data) => {
  //   console.log(data, "data");
  //   if (!data.url && !data.message) {
  //     alert("Please enter a page URL or text in the box");
  //     return false;
  //   }

  //   const postData = {
  //     url: data.url,
  //     text: data.message,
  //     ...formCommonPostData(),
  //   };

  //   mutateAsync(postData).then((response) => {
  //     if (response.success === 1) {
  //       setEnteredText(postData.text || postData.url);
  //       setSummarizedText(response.data.result);
  //       setToggleTool(true);
  //       reset();
  //       window.scrollTo(0, 0);
  //     } else {
  //       alert(response.message);
  //     }
  //   });
  // };
  const onSubmit = (data) => {
    
  

    if (!data.url && !data.message) {
      alert("Please enter a page URL or text in the box");
      return;
    }

    const postData = {
      url: data.url || undefined, 
      text: data.message || undefined, 
    };
  

    mutateAsync(postData).then((response) => {
      if (response.success === 1) {
        setEnteredText(postData.text || postData.url);
        setSummarizedText(response.data.result);
        setToggleTool(true);
        reset();
        window.scrollTo(0, 0);
      } else {
        alert(response.message);
      }
    });
  };
  
  const handleTextChange = (e) => {
    const text = e.target.value.trim();
    setMessageText(text);
    const words = text.split(/\s+/).filter((word) => word.length > 0);
    setWordCount(words.length);
  };

  const clearText = () => {
    setWordCount(0);
    setMessageText("");
    reset({ message: "" });
    setSummarizedText("")
  };

  const handleLinkChange = (e) => {
    const text = e.target.value;
    setUrlText(text);
  };

  const clearLink = () => {
    setUrlText("");
    reset({ url: "" });
    setSummarizedText("")
  };

  const toggleInput = (isTextInput) => {
    setIsEnterText(isTextInput);
    // if (isTextInput) {
    //   setUrlText(""); // Clear URL input if switching to text
    // } else {
    //   setMessageText(""); // Clear message input if switching to URL
    // }
  };

  return (
    <div className="bg-[#F5F5F5] ">
      
        <div className=" w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>
    
      <div className="pb-[120px]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 mt-12 lg:mt-16">
          <h1 className="text-[#5B93FF] font-semibold text-base font-primary text-center ">
            SUMMARY GENERATOR
          </h1>
          <h2 className="mt-3 text-[#0A2641] font-primary text-[28px] lg:text-[64px] font-bold leading-[48px] lg:leading-[77px] text-center ">
            Summarize your long text for free
          </h2>
          <p className="mt-6 font-opensans max-w-3xl mx-auto text-[#424242] text-lg font-normal text-center">
            Our text summarizer helps you quickly generate concise summaries
            from articles, PDFs, and websites. Just paste your content or a URL,
            and get a summary instantly.
          </p>
          <div className="grid lg:grid-cols-2 gap-3 mt-16">
            <div className="bg-[#ffffff] rounded-lg shadow-lg p-5">
              <div className="flex gap-1">
                <button
                  onClick={() => toggleInput(true)}
                  className={`py-1 px-3 rounded-md font-semibold ${
                    isEnterText
                      ? "text-white bg-[#7AA7FF]"
                      : "text-[#7AA7FF] bg-[#F0F5FF]"
                  }`}
                >
                  Enter Text
                </button>
                <button
                  onClick={() => toggleInput(false)}
                  className={`py-1 px-3 rounded-md font-semibold ${
                    isEnterText
                      ? "text-[#7AA7FF] bg-[#F0F5FF]"
                      : "text-white bg-[#7AA7FF]"
                  }`}
                >
                  Enter URL
                </button>
              </div>
              <div className="mt-4">
                {isEnterText ? (
                  <div>
                    <textarea
                      {...register("message")}
                      value={messageText}
                      className="min-h-[280px] font-opensans placeholder-[#858585] text-black leading-[26px] py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full"
                      placeholder="Type or paste your text here"
                      onChange={handleTextChange}
                    />
                    <div className="flex justify-between items-center p-4 mt-8">
                      <p className="text-right mt-2 text-sm text-[#9C9C9C]">
                        {wordCount} words
                      </p>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className={` ${
                            wordCount > 0
                              ? "text-[#0A2641] border border-[#0A2641] cursor-pointer"
                              : "border border-[#B0B0B0] text-[#B0B0B0]"
                          } flex gap-2 bg-white px-4 py-2 rounded-md`}
                          onClick={clearText}
                          disabled={wordCount === 0}
                        >
                          Clear text
                        </button>
                        <button
                          type="button"
                          onClick={handleSubmit(onSubmit)}
                          className="bg-[#0A2641] text-white px-4 py-2 rounded-md"
                        >
                          {loading ? <Loader /> : "Summarize text"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <textarea
                      {...register("url")}
                      value={urlText}
                      placeholder="Paste your link here"
                      className="min-h-[220px] font-opensans placeholder-[#858585] text-[#1463FF] leading-[26px] py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full"
                      onChange={handleLinkChange}
                    />
                    <div className="flex justify-between items-center p-4 mt-8">
                      <span></span>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          className={` ${
                            urlText
                              ? "text-[#0A2641] border border-[#0A2641] cursor-pointer"
                              : "border border-[#B0B0B0] text-[#B0B0B0]"
                          } flex gap-2 bg-white px-4 py-2 rounded-md`}
                          onClick={clearLink}
                          disabled={urlText === 0}
                        >
                          Clear link
                        </button>
                        <button
                          type="button"
                          onClick={handleSubmit(onSubmit)}
                          className="bg-[#0A2641] text-white px-4 py-2 rounded-md"
                        >
                          {loading ? <Loader /> : "Summarize link"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="bg-[#ffffff] opacity-50 rounded-lg shadow-lg p-5">
              <div className="flex justify-between gap-1">
                <p className="text-base text-[#B0B0B0] font-bold leading-6 font-opensans">
                  Summarized text
                </p>
                {/* <p className="text-base text-[#B0B0B0] font-bold leading-6 font-opensans flex gap-2 items-center">
                  <img src={Toogle_icon} alt="/toogle_icon" />
                  Show bullets
                </p> */}
              </div>
              <div className="mt-4">
                <textarea
                  value={summarizedText}
                  className="min-h-[280px] font-opensans placeholder-[#858585] text-black leading-[26px] py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full"
                  placeholder="Summarized text will appear here"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        <p className="w-full text-center text-base mt-12 pb-4 text-[#9C9C9C]">
          Assisted by AI tools. Inaccuracies may occur.
          </p>
      </div>
    </div>
  );
}

export default FirstSummaryTool;
